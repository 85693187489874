import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AccountsService } from '@lib/frontend-shared-accounts-data-accounts-service';
import { AuthenticationService } from '@lib/frontend-shared-accounts-data-authentication-service';
import { map, Observable } from 'rxjs';
import { mapIfDefined } from '@lib/shared-util-rxjs';
import {
    SchoolAccountPlan,
    SchoolAccountRole,
    SchoolUserType,
} from '@lib/shared-interface-account';
import { BrandedNavigationComponent } from '@lib/frontend-shared-feature-branded-navigation';
import {
    RequirePlanDirective,
    RequireRoleDirective,
} from '@lib/frontend-shared-data-permissions-service';

@Component({
    standalone: true,
    selector: 'lib-school-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        BrandedNavigationComponent,
        RequireRoleDirective,
        IonicModule,
        RouterModule,
        TranslateModule,
        RequirePlanDirective,
    ],
})
export class NavigationComponent {
    protected readonly account$ = this.accountsService.userAccount$;
    protected readonly isLoggedIn$: Observable<boolean> = this.account$.pipe(map(Boolean));
    protected readonly schoolAccountPlan = SchoolAccountPlan;

    protected readonly isIndependentUser$ = this.account$.pipe(
        map((account) => {
            const userType = account?.additionalSchoolUserData?.userType;
            return userType !== SchoolUserType.DEPENDENT_AFFILIATED;
        }),
    );
    protected readonly accountTitle$: Observable<string | undefined> = this.account$.pipe(
        map((account) => account?.fullName ?? account?.email),
    );
    protected readonly accountAvatar$: Observable<Image | undefined> = this.account$.pipe(
        mapIfDefined(({ fullName, email, avatar }) => {
            if (!avatar) return;

            const alternateText = fullName ?? email;
            return { source: avatar, alternateText };
        }),
    );

    protected readonly schoolAccountRole = SchoolAccountRole;

    protected readonly socialLinks = SOCIAL_LINKS;

    public constructor(
        private readonly accountsService: AccountsService,
        private readonly authenticationService: AuthenticationService,
        private readonly navigationController: NavController,
    ) {}

    protected async logOut() {
        await this.authenticationService.signOut();
        await this.navigationController.navigateRoot(['account', 'login']);
    }

    protected openHelpScout() {
        Beacon('open');
    }
}

// Five social links fit comfortably on the side menu;
// any more links, and we should rethink their placement
// or perhaps turn on flex wrap.
const SOCIAL_LINKS: SocialLink[] = [
    {
        href: 'https://www.instagram.com/ebacademics/',
        iconName: 'logo-instagram',
    },
    {
        href: 'https://www.facebook.com/ebacademicsteachers',
        iconName: 'logo-facebook',
    },
    {
        href: 'https://podcasts.apple.com/us/podcast/teaching-middle-school-ela/id1458448897',
        iconSource: '/assets/icons/podcast.svg',
    },
    {
        href: 'https://www.youtube.com/channel/UCWezgZ_BNFaF-IURmTTN_dA',
        iconName: 'logo-youtube',
    },
    {
        href: 'https://www.linkedin.com/company/eb-academics-teachers/',
        iconName: 'logo-linkedin',
    },
];

type SocialLink = {
    href: string;
    iconName?: string;
    iconSource?: string;
};

type Image = {
    source: string;
    alternateText: string;
};

type BeaconApi = (command: string) => void;
// The HelpScout Beacon API provides a global that does not follow our naming conventions.
// eslint-disable-next-line  @typescript-eslint/naming-convention
declare let Beacon: BeaconApi;
