import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { PermissionsService } from './permissions.service';
import { SchoolAccountRole } from '@lib/shared-interface-account';

@Directive({
    selector: '[libRequireRole]',
    standalone: true,
})
export class RequireRoleDirective implements OnInit {
    @Input({ alias: 'libRequireRole', required: true }) public role!:
        | SchoolAccountRole
        | SchoolAccountRole[];

    public constructor(
        private readonly permissionsService: PermissionsService,
        private readonly template: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    public async ngOnInit(): Promise<void> {
        const roles = Array.isArray(this.role) ? this.role : [this.role];
        const userHasRoles = roles.length === 0 || (await this.permissionsService.hasRoles(roles));

        userHasRoles
            ? this.viewContainer.createEmbeddedView(this.template)
            : this.viewContainer.clear();
        this.changeDetector.markForCheck();
    }
}
